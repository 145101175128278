import Default from '../../../common/Themes/Default/Theme';
import * as boldIcons from '../../../common/IconPacks/boldOutline';
import * as socialIconPack from '../../../common/IconPacks/SocialIcons/defaultSocialIconPack';
import { Cascade } from '../../../common/loaders';
import { FILL, BLUR } from '../../../common/constants/headerTreatments';
import themeConfig from '../themeConfig';
import { WIDGETS_WITH_CIRCULAR_IMAGES } from '../../../common/constants';

const typographyShared1 = {
  style: {
    font: 'primary',
    color: 'highlight',
    fontSize: 'xlarge',
    fontWeight: 'normal',
    letterSpacing: 'normal',
    textTransform: 'none'
  }
};

class Theme19 extends Default {
  static config = themeConfig;

  static get displayName() {
    return 'Theme19';
  }

  static excludedProps = Default.excludedProps.concat('tagline2');

  static getMutatorDefaultProps(widgetType, widgetPreset) {
    const defaultProps = super.getMutatorDefaultProps(widgetType, widgetPreset);
    const enableCircularImage =
      WIDGETS_WITH_CIRCULAR_IMAGES[widgetPreset] || defaultProps.enableCircularImage;

    return widgetType === 'HEADER'
      ? {
        ...defaultProps,
        hasLogoAlign: true,
        usePhone: true,
        phoneOnSecondaryPage: true,
        useSubtagline: false,
        headerTreatmentsConfig: {
          headerTreatments: [FILL, BLUR],
          defaultHeaderTreatment: FILL
        },
        coverImagePivot: {},
        disableCTAList: true
      }
      : {
        ...defaultProps,
        enableCircularImage
      };
  }

  static getWidgetDefaultProps(widgetType, widgetPreset) {
    return {
      ...super.getWidgetDefaultProps(widgetType, widgetPreset),
      ...(widgetType === 'HEADER' && { alignmentOption: 'right' })
    };
  }

  constructor() {
    super();
    this.mappedValues = {
      ...this.mappedValues,
      typographyOverrides: {
        LogoAlpha: {
          ...typographyShared1
        },
        HeadingAlpha: ({ shadow = false }) => {
          return {
            style: {
              font: 'primary',
              color: 'highlight',
              fontSize: 'xxlarge',
              fontWeight: 'normal',
              letterSpacing: 'normal',
              textTransform: 'none',
              ...(shadow && {
                textShadow: '2px 2px 0px rgba(255, 255, 255, 0.3)'
              })
            }
          };
        },
        HeadingBeta: {
          style: {
            font: 'primary',
            color: 'highContrast',
            fontSize: 'xlarge',
            fontWeight: 'normal',
            letterSpacing: 'normal',
            textTransform: 'none',
            textShadow: '2px 2px 0px rgba(0, 0, 0, 0.2)'
          }
        },
        HeadingGamma: {
          ...typographyShared1
        },
        HeadingDelta: {
          style: {
            font: 'alternate',
            color: 'highContrast',
            fontSize: 'medium',
            fontWeight: 'normal',
            letterSpacing: '0.125em',
            textTransform: 'uppercase'
          }
        },
        HeadingEpsilon: {
          style: {
            font: 'primary',
            color: 'highContrast',
            fontSize: 'large',
            fontWeight: 'normal',
            letterSpacing: 'normal',
            textTransform: 'none'
          }
        },
        BodyGamma: {
          style: {
            font: 'alternate',
            color: 'highlight',
            fontSize: 'large',
            fontWeight: 'normal',
            letterSpacing: 'normal',
            textTransform: 'none'
          }
        },
        NavAlpha: {
          style: {
            font: 'alternate',
            color: 'highContrast',
            fontSize: 'small',
            fontWeight: 'normal',
            letterSpacing: '0.125em',
            textTransform: 'uppercase'
          },
          active: {
            style: {
              color: 'highContrast'
            }
          }
        },
        NavBeta: {
          style: {
            font: 'alternate',
            color: 'highContrast',
            fontSize: 'large',
            fontWeight: 'normal',
            letterSpacing: '0.125em',
            textTransform: 'uppercase'
          }
        },
        ButtonAlpha: props => {
          const { size = 'default' } = props;
          const sizes = {
            small: {
              fontSize: 'xsmall'
            },
            default: {
              fontSize: 'small'
            },
            large: {
              fontSize: 'small'
            }
          };
          return {
            style: {
              font: 'alternate',
              fontWeight: 'bold',
              letterSpacing: '0.125em',
              textTransform: 'uppercase',
              ...sizes[size]
            }
          };
        }
      }
    };
  }

  getSection(inputSection) {
    if (inputSection === 'default') {
      return 'alt';
    } else if (inputSection === 'overlay') {
      return 'overlay';
    }

    return 'default';
  }

  HeadingProduct(props) {
    return this.HeadingMinor(
      this.merge(
        {
          typography: 'BodyBeta',
          featured: true
        },
        props
      )
    );
  }

  Input(props) {
    const { section } = props;
    return super.Input(
      this.merge(
        {
          section: this.getSection(section || this.base.section),
          style: {
            borderBottomWidth: 0,
            paddingBottom: 'small',
            backgroundColor: 'section',
            borderColor: 'input',
            paddingHorizontal: 'small',
            paddingTop: 'small',
            borderRadius: '0',
            borderStyle: 'solid'
          }
        },
        props
      )
    );
  }

  Tagline(props) {
    return super.Tagline(
      this.merge(
        {
          shadow: true
        },
        props
      )
    );
  }

  SectionHeading(props) {
    const { layout = 'full' } = props;
    return super.SectionHeading(
      this.merge(
        {
          style: {
            textAlign: 'center',
            ['@md']: {
              textAlign: layout === 'full' ? 'center' : 'left'
            }
          }
        },
        props
      )
    );
  }

  WelcomeContentContentBasic(props) {
    return super.ContentBasic(
      this.merge(
        {
          style: {
            alignItems: 'center'
          }
        },
        props
      )
    );
  }

  FeaturedHeading(props) {
    return super.FeaturedHeading(this.merge({ typography: 'HeadingDelta' }, props));
  }

  FeaturedText(props) {
    return super.FeaturedText(
      this.merge(
        {
          style: {
            '@md': {
              maxWidth: 600
            }
          },
          typography: 'BodyGamma'
        },
        props
      )
    );
  }

  UtilitiesMenuLink(props) {
    return super.UtilitiesMenuLink(
      this.merge(
        {
          style: {
            '@md': {
              color: 'highlight',
              [':hover']: {
                color: 'highContrast'
              }
            }
          },
          typography: false
        },
        props
      )
    );
  }

  Icon(props) {
    return super.Icon(
      this.merge(
        {
          iconPack: { ...boldIcons, ...socialIconPack }
        },
        props
      )
    );
  }

  Loader(props) {
    return Cascade.apply(this, [props]);
  }

  UtilitiesMenuIcon(props) {
    return super.UtilitiesMenuIcon(
      this.merge(
        {
          style: {
            '@md': {
              color: 'highlight',
              [':hover']: {
                color: 'highContrast'
              }
            }
          },
          typography: false
        },
        props
      )
    );
  }

  Pipe(props) {
    return super.Pipe(
      this.merge(
        {
          style: {
            height: '1em',
            backgroundColor: 'sectionContrast'
          }
        },
        props
      )
    );
  }

  InputTextArea(props) {
    return super.InputTextArea(
      this.merge(
        {
          rows: 5
        },
        props
      )
    );
  }

  InputSelect(props) {
    const { section } = props;
    return super.InputSelect(
      this.merge(
        {
          section: this.getSection(section || this.base.section),
          style: {
            backgroundColor: 'section',
            borderColor: 'input',
            borderBottomWidth: 'medium',
            borderStyle: 'solid',
            borderRadius: 'none',
            ['@xs-only']: {
              fontSize: 'medium' // 16px
            }
          }
        },
        props
      )
    );
  }

  InputSelectElement(props) {
    return super.InputSelectElement(
      this.merge(
        {
          style: {
            paddingHorizontal: 'small',
            paddingTop: 'small',
            paddingBottom: '11px',
            ['@xs-only']: {
              fontSize: 'medium' // 16px
            }
          }
        },
        props
      )
    );
  }

  Table(props) {
    return super.Table(
      this.merge(
        {
          style: {
            borderRadius: 0
          }
        },
        props
      )
    );
  }

  MediaObjectBackground(props) {
    return super.MediaObjectBackground(
      this.merge(
        {
          style: {
            borderRadius: 0
          }
        },
        props
      )
    );
  }

  HeadingMajor(props) {
    return super.HeadingMajor(this.merge({ typography: 'HeadingAlpha', featured: true }, props));
  }

  Phone(props) {
    return super.Phone(
      this.merge(
        {
          style: {
            a: {
              font: 'inherit'
            },
            span: {
              font: 'inherit'
            }
          },
          typography: 'HeadingEpsilon'
        },
        props
      )
    );
  }

  CardBannerHeading(props) {
    return super.CardBannerHeading(
      this.merge({ typography: 'HeadingAlpha', featured: true }, props)
    );
  }

  Intro(props) {
    return super.Intro(this.merge({ alignment: 'center' }, props));
  }

  ContentCard(props) {
    const styles = this.base.widgetPreset === 'about1' ? { style: { alignItems: 'center' } } : {};
    return super.ContentCard(this.merge(styles, props));
  }
}

export default Theme19;
